.slideshow-container .mySwiper {
    width: 100%;
    height: 73vh;
}

.slideshow-container .mySwiper .swiperslide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.swiper-button-prev{
    --swiper-navigation-size: 60px;
    background-image: url('../assets/logos/left-arrow.png');
}

.swiper-button-next{
    --swiper-navigation-size: 60px;
    background-image: url('../assets/logos/right-arrow.png');
}

.swiper-button-next,
.swiper-button-prev{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.swiper-button-next::after,
.swiper-button-prev::after{
    display: none;
}