@import url("https://fonts.googleapis.com/css2?family=Lovers+Quarrel&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Old+Standard+TT&display=swap");

.moving-text {
  background-color: rgb(58, 52, 52);
  color: whitesmoke;
  height: 40px;
  display: flex;
  align-items: center;
}

.moving-text a {
  text-decoration: none;
  color: white;
}

.slide-splitter {
  position: relative;
background: white;
  height: 60px;
}

.slide-splitter::before {
  position: absolute;
  content: "";
  left: 50%;
  z-index: 1;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: inherit;
  background-image: url("../assets/logos/double-down.png");
  background-repeat: no-repeat;
  background-size: 45px;
  background-position: center;
  transform: translateX(-50%) translateY(50%);
  bottom: 0px;
  cursor: pointer;
}

.school-tagline {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lovers Quarrel", cursive;
  font-size: 6.5rem;
  background-image : linear-gradient(#f8a57b, #ff9500);
  height: 300px;
}

.spikes {
  position: relative;
  background: #ff9500;
}

.spikes::after {
  content: '';
  position: absolute;
  right: 0;
  left: -0%;
  top: 100%;
  z-index: 10;
  display: block;
  height: 50px;
  background-size: 50px 100%;
  background-image: linear-gradient(135deg, #ff9500 25%, transparent 25%), linear-gradient(225deg, #2c3e50 25%, transparent 25%);
  background-position: 0 0;
}

.img-words-parent {
  background-color : wheat;
}

.img-words-parent h1 {
  padding-top: 50px;
  text-align: center;
  font-family: "Old Standard TT", serif;
  font-size: 3rem;
  letter-spacing: 2px;
}

.img-words {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.principal-img {
  padding-top: 45px;
}

.principal-words {
  width: 50vw;
  padding-top: 45px;
}

.curved {
  position: relative;
  background: wheat;
  height: 100px;
  border-bottom-left-radius: 50% 29%;
  border-bottom-right-radius: 50% 29%;
}

.facilities-parent {
  margin-top: 100px;
  text-align: center;
}

.facilities-parent h1 {
  font-family: "Old Standard TT", serif;
  letter-spacing: 2px;
  font-size: 3rem;
}

.facilities-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 40px;
}

.facility-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 450px;
  height: 250px;
  width: 450px;
  text-align: center;
  border: 2px black solid;
  padding: 30px;
  margin-top: 20px;
}

.facility-div > h4 {
  margin-bottom: 10px;
}

.googlemap {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color : rgb(234, 220, 194);
  height: 600px;
}

.school-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 300px;
  background-color : #FFAC31;
  flex-wrap: wrap;
}

.ul-properties {
  list-style: none;
  margin: 0;
  padding: 0;
}

.contact-info{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.hz-list{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.hz-list li {
  font-size: 2.5rem;
}

.icon-link{
  width: 80px;
  height: 80px;
  background-color: #fff;
  text-align: center;
  line-height: 80px;
  font-size: 35px;
  margin: 0 10px;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 3px solid #fff;
  z-index: 1;
}

.icon{
  position: relative;
  color: #262626;
  transition: .5s;
  z-index: 3;
}

.icon-link:hover .icon {
  color: #fff;
  transform: rotateY(360deg);
}

.icon-link:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f00;
  transition: .5s;
  z-index: 2;
}

.icon-link:hover:before {
  top: 0;
}

.hz-list li:nth-child(1) .icon-link:before{
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}

.hz-list li:nth-child(2) .icon-link:before{
  background: #3b5999;
}

.hz.list li:nth-child(3) .icon-link:before {
  background: red;
}

#school-email{
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
}

#school-email:hover{
  color: rgb(142, 96, 11);
}

.copyright-div{
  display: flex;
  justify-content: center;
  align-items: center;
  height : 50px;
  background-color : #FFAC31;
  font-size: 0.87rem;
}