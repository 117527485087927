.book-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
    background: linear-gradient(#ef9b26, #e6be3d);
    margin-top: -30px;
}

/* Book */
.book {
    position: relative;
    width: 350px;
    height: 500px;
    transition: transform 0.5s;
}

.paper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    perspective: 1500px;
}

.front,
.back {
    background-color: white;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform-origin: left;
    transition: transform 0.5s;
    border-radius: 40px;
}

.front {
    z-index: 1;
    backface-visibility: hidden;
    border-left: 3px solid black;
}

.back {
    z-index: 0;
}

.front-content,
.back-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 2em;
}

.back-content {
    transform: rotateY(180deg)
}

/* Paper flip effect */
.flipped .front,
.flipped .back {
    transform: rotateY(-180deg);
}

/* Controller Buttons */
.flip-btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin: 10px;
    transition: transform 0.5s;
}

.flip-btn:focus {
    outline: none;
}

.flip-btn:hover i {
    color: #636363;
}

.flip-btn>i {
    font-size: 50px;
    color: white;
}

/* Paper stack order */
#flipPage1 {
    z-index: 10;
}

#flipPage2 {
    z-index: 9;
}

#flipPage3 {
    z-index: 8;
}

#flipPage4 {
    z-index: 7;
}

#flipPage5 {
    z-index: 6;
}

#flipPage6 {
    z-index: 5;
}

#flipPage7 {
    z-index: 4;
}

#flipPage8{
    z-index : 3;
}

#flipPage9{
    z-index : 2;
}

#flipPage10{
    z-index : 1;
}

.back-content>img {
    height: 300px;
    width: 290px;
    border: 2px solid black;
}

.front-content>h2 {
    text-align: center;
    padding: 1em 0;
    margin-top: -2em;
}

.front-content>p {
    text-align: justify;
}